import React, { useState } from 'react';
import styles from './priceCard.module.scss';
import xmark from '../../Assets/Images/Icons/Other/xmark.png';
import checkmark from '../../Assets/Images/Icons/Other/checkmark.png';
import Button from '../Button/Button';
import { getItem, setItem } from '../../Services/storage.handler';
import { useRecoilState } from 'recoil';
import { itemCountState } from '../../Recoil/Atoms';
import { currencyFormat } from '../../Pages/Private/Invoices/InvoiceView/Invoice';

const PriceCard = ({ type, title, price, services, covers }) => {
    const [, setItemCount] = useRecoilState(itemCountState);
    const [smallVehicle, setSmallVehicle] = useState(true);
    const [cardPrice, setCardPrice] = useState(price);

    const renderServices = () => {
        // Create an array to store indexes of covered services
        const coveredIndexes = [];

        // Populate the array with indexes of covered services
        services.forEach((service, index) => {
            if (covers[index]) {
                coveredIndexes.push(index);
            }
        });

        // Sort the coveredIndexes array in ascending order
        coveredIndexes.sort((a, b) => {
            return services[a].localeCompare(services[b]);
        });

        // Map over the sorted coveredIndexes array and render covered service items first
        return coveredIndexes
            .map((index) => (
                <div key={index} className={styles.serviceItem}>
                    <img src={checkmark} alt='Covered' />
                    {services[index]}
                </div>
            ))
            .concat(
                // Map over the remaining not covered services and render them
                services
                    .map((service, index) => index)
                    .filter((index) => !covers[index])
                    .sort((a, b) => {
                        return services[a].localeCompare(services[b]);
                    })
                    .map((index) => (
                        <div key={index} className={styles.serviceItem}>
                            <img src={xmark} alt='Not Covered' />
                            {services[index]}
                        </div>
                    )),
            );
    };

    const buyButtonHandler = () => {
        let correctedTitle = `${type} - ${title}`;

        if (!smallVehicle) {
            correctedTitle += ' (Large Vehicle)';
        }

        const currentItem = {
            title: correctedTitle,
            price: cardPrice,
        };

        let cartItems = getItem('cart');
        if (!cartItems) {
            cartItems = [];
        }
        cartItems.push(currentItem);
        setItemCount(cartItems.length);
        setItem('cart', cartItems);
    };

    const handleToggle = () => {
        if (smallVehicle) {
            setCardPrice(cardPrice * 1.5);
        } else {
            setCardPrice(cardPrice / 1.5);
        }
        setSmallVehicle(!smallVehicle);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>{title}</h2>
                <div className={styles.vehicleSelector}>
                    <p>
                        Coupe
                        <br />
                        Sedan
                    </p>
                    <label className={styles.switch}>
                        <input
                            type='checkbox'
                            checked={!smallVehicle}
                            onChange={() => handleToggle()}
                        />
                        <span className={`${styles.slider} ${styles.round}`}></span>
                    </label>
                    <p>
                        Van
                        <br />
                        Suv
                        <br />
                        Truck
                    </p>
                </div>
                <p>{currencyFormat.format(cardPrice)}</p>
            </div>
            <div className={styles.serviceWrapper}>{renderServices()}</div>
            <div className={styles.buttonContainer}>
                <Button buttonText='Buy' onClick={buyButtonHandler} />
            </div>
        </div>
    );
};

export default PriceCard;
