import React, { useState, useEffect } from 'react';
import Stripe from 'stripe';
import styles from './shoppingcart.module.scss';
import trash from '../../Assets/Images/Icons/Other/trash.png';
import close from '../../Assets/Images/Icons/Other/close-black.png';
import Loader from '../Loader/Loader';
import { currencyFormat, truncateText } from '../../Pages/Private/Invoices/InvoiceView/Invoice';
import { getItem, setItem } from '../../Services/storage.handler';
import { useRecoilState } from 'recoil';
import { itemCountState, screenWidthState } from '../../Recoil/Atoms';
import { makePurchase } from '../../Services/api.service';
import InputField from '../InputField/InputField';
import Button from '../Button/Button';
import checkmark from '../../Assets/Images/Icons/Other/check.png';
import errorImg from '../../Assets/Images/Icons/Other/close.png';
import DatePicker, { dateToUnixTimeStamp } from '../DatePicker/DatePicker';

const Cart = ( {handleCloseButton} ) => {
    const [items, setItems] = useState(getItem('cart') || []);
    const [loading, setLoading] = useState(true);
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpMonth, setCardExpMonth] = useState('');
    const [cardExpYear, setCardExpYear] = useState('');
    const [cardCvc, setCardCvc] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [plate, setPlate] = useState('');
    const [dateTs, setDateTs] = useState(dateToUnixTimeStamp(new Date()));
    const [totalPrice, setTotalPrice] = useState(0);
    const [cardType, setCardType] = useState('visa.png');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [paidTotal, setPaidTotal] = useState('');
    const [, setItemCount] = useRecoilState(itemCountState);
    const [screenWidth, ] = useRecoilState(screenWidthState);

    useEffect(() => {
        const cartItems = getItem('cart');
        if (cartItems) {
            setItems(cartItems);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        let total = 0;
        items.map((i) => (total += i.price));
        setTotalPrice(total);
    }, [items]);

    // Set your Stripe API key
    const stripe = new Stripe(process.env.REACT_APP_STRIPE_KEY);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateInput()) {
            const totalWithTax = currencyFormat.format(totalPrice * 1.13).substring(1);
            let fixedAmount = totalWithTax.replaceAll(',', '');
            let fixedCardNumber = cardNumber.replaceAll(' ', '');

            if (!fixedAmount.includes('.')) {
                fixedAmount += '.00';
            } else {
                while (fixedAmount.split('.')[1].split('').length < 2) {
                    fixedAmount += '0';
                }
            }

            const confirmBox = window.confirm(
                `Please confirm,\n\nYour plate is ${plate} and your email is ${email}`,
            );
            if (confirmBox === true) {
                setPaidTotal(currencyFormat.format(totalPrice * 1.13));
                setLoading(true);
                stripe.tokens
                    .create({
                        card: {
                            number: fixedCardNumber,
                            exp_month: cardExpMonth,
                            exp_year: cardExpYear,
                            cvc: cardCvc,
                        },
                    })
                    .then((result) => {
                        result.date = dateTs;
                        result.items = items;
                        result.plate = plate;
                        result.phoneNumber = phoneNumber;
                        result.customerName = customerName;
                        result.email = email;
                        result.amount = fixedAmount;
                        makePurchase(result)
                            .then(() => {
                                setLoading(false);
                                setSuccess(true);

                                setItems([]);
                                setItemCount(0);
                                setItem('cart', []);
                            })
                            .catch(() => {
                                setLoading(false);
                                setError(true);
                            });
                    })
                    .catch(() => {
                        setLoading(false);
                        setError(true);
                    });
            }
        }
    };

    const handleCardNumberChange = (event) => {
        if (event.target.value.length < 20) {
            const formattedInput = event.target.value
                .replace(/[^\d]/g, '')
                .replace(/\d{1,4}/g, (match) => {
                    return match + ' ';
                })
                .trim();
            setCardNumber(formattedInput);
            if (event.target.value.substring(0, 2) > 50) {
                setCardType('mastercard.png');
            } else {
                setCardType('visa.png');
            }
        }
    };

    const handleCardExpMonthChange = (event) => {
        const newMonth = event.target.value.replace(/[^\d]/g, '').slice(0, 2);
        if (/^(\d{0,1}|0?[1-9]|1[0-2])$/.test(newMonth)) {
            setCardExpMonth(newMonth);
        }
    };

    const handleCardExpYearChange = (event) => {
        const year = event.target.value;
        if (/^\d{0,4}$/.test(year)) {
            setCardExpYear(year);
        }
    };

    const handleCvcInput = (event) => {
        const newValue = event.target.value;
        if (/^\d{0,3}$/.test(newValue)) {
            setCardCvc(newValue);
        }
    };

    const removeFromCart = (index) => {
        // Create a new array without the item to be removed
        const updatedItems = [...items.slice(0, index), ...items.slice(index + 1)];

        // Update the state with the new array
        setItems(updatedItems);

        // Update local storage with modified cart data
        setItemCount(updatedItems.length);
        setItem('cart', updatedItems);
    };

    const validateInput = () => {
        const today = new Date();
        const masterCardValidation =
            /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;
        const visaCardValidation = /^4[0-9]{12}(?:[0-9]{3})?$/;
        const formattedCard = cardNumber.replaceAll(' ', '');

        let errorText = '';

        if (customerName === '') {
            errorText += 'Please enter your name\n';
        }

        if (email === '') {
            errorText += 'Please enter your email\n';
        }

        if (phoneNumber === '') {
            errorText += 'Please enter phone number\n';
        }

        if (plate === '') {
            errorText += 'Please enter your plate\n';
        }

        if (!(visaCardValidation.test(formattedCard) || masterCardValidation.test(formattedCard))) {
            errorText += 'Please enter a valid card number\n';
        }

        if (cardExpMonth < 1 || cardExpMonth > 12) {
            errorText += 'Enter a valid month\n';
        }

        if (cardExpYear < today.getFullYear()) {
            errorText += 'Year must be in the future\n';
        }

        if (cardExpYear === today.getFullYear() && cardExpMonth < today.getMonth() + 1) {
            errorText += 'Card expiry must be in the future\n';
        }

        if (cardCvc.length < 3) {
            errorText += 'Cvc must be in the correct format\n';
        }

        if (errorText !== '') {
            window.alert(errorText);
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <div className={styles.cart}>
                <div className={styles.closeButton}>
                    <h2>Shopping Cart</h2>
                    <img onClick={() => handleCloseButton()} src={close} alt='close' />
                </div>
                {loading ? (
                    <Loader />
                ) : error ? (
                    <>
                        <div className={styles.paymentSuccess}>
                            <img src={errorImg} alt='success' />
                            <h2>Payment failed</h2>
                            <h4>
                                You can either refresh this page and try again, or get in touch with
                                your card provider.
                            </h4>
                        </div>
                    </>
                ) : success ? (
                    <>
                        <div className={styles.paymentSuccess}>
                            <img src={checkmark} alt='success' />
                            <h2>Thank you !</h2>
                            <h4>Your payment of {paidTotal} is received.</h4>
                        </div>
                    </>
                ) : items.length === 0 ? (
                    <p>Your cart is empty.</p>
                ) : (
                    <div className={styles.cartInnerContainer}>
                        <div className={styles.cartWrapper}>
                            {items.map((item, index) => (
                                <div key={`${index}${item.title}`} className={styles.cartItem}>
                                    <img
                                        onClick={() => removeFromCart(index)}
                                        src={trash}
                                        alt='remove'
                                    />
                                    <p>{truncateText(item.title, 30, screenWidth)}</p>
                                    <p>$ {item.price}</p>
                                </div>
                            ))}
                        </div>
                        <div className={styles.cartInnerFooter}>
                            <p>Sub-Total : {currencyFormat.format(totalPrice)}</p>
                            <p>Tax : {currencyFormat.format(totalPrice * 0.13)}</p>
                            <b>
                                <p>Total : {currencyFormat.format(totalPrice * 1.13)}</p>
                            </b>
                        </div>
                        <div className={styles.checkoutContainer}>
                            <h3>Continue to checkout</h3>
                            <div>
                                <InputField
                                    label='Name'
                                    value={customerName}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    placeholder='John Doe'
                                />
                                <InputField
                                    label='Email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='john.doe@gmail.com'
                                />
                                <InputField
                                    label='Phone Number'
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder='123-456-7890'
                                />
                                <InputField
                                    label='Plate'
                                    value={plate}
                                    onChange={(e) => setPlate(e.target.value)}
                                    placeholder='ABCD123'
                                />
                                <div className={styles.dateWrapper}>
                                    <h4>Preferred Date</h4>
                                    <DatePicker
                                        initialTimestamp={dateTs}
                                        onChange={(unixTimeStamp) => setDateTs(unixTimeStamp)}
                                    />
                                </div>
                                <InputField
                                    type='text'
                                    pattern='\d*'
                                    value={cardNumber}
                                    path={cardType}
                                    placeholder='1111 1111 1111 1111'
                                    label='Card Number'
                                    onChange={handleCardNumberChange}
                                />
                                <div className={styles.monthYearBox}>
                                    <InputField
                                        type='text'
                                        pattern='\d*'
                                        value={cardExpMonth}
                                        label='Month'
                                        onChange={handleCardExpMonthChange}
                                        placeholder='MM'
                                        maxLength={2}
                                    />
                                    <InputField
                                        type='text'
                                        pattern='\d*'
                                        value={cardExpYear}
                                        label='Year'
                                        onChange={handleCardExpYearChange}
                                        placeholder='YYYY'
                                        maxLength={4}
                                    />
                                </div>
                                <InputField
                                    label='Cvc Number'
                                    pattern='\d*'
                                    value={cardCvc}
                                    onChange={handleCvcInput}
                                    placeholder='123'
                                    maxLength={3}
                                />
                                <div className={styles.buttonWrapper}>
                                    <Button buttonText='Pay Now' onClick={handleSubmit} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Cart;
