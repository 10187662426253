import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderComponent from '../../../../Components/Header/Header';
import FooterComponent from '../../../../Components/Footer/Footer';
import PriceCard from '../../../../Components/PriceCard/PriceCard';
import Stories from '../../../../Components/Stories/Stories';
import styles from './services.module.scss';

import OurServices from '../../../../Data/Services.json';
import StoryData from '../../../../Data/Gallery.json';

import { Helmet } from 'react-helmet-async';

const Services = () => {
    const navigate = useNavigate();
    const { title } = useParams();
    const [currentService, setCurrentService] = useState({});
    const [filteredData] = useState(
        // StoryData.filter((s) =>
        //     s.tags.some((tag) => tag === title.replace(/\s/g, '').toLowerCase()),
        // ),
        StoryData,
    );

    useEffect(() => {
        const serviceComponent = OurServices.find((service) => service.serviceName === title);

        if (serviceComponent) {
            setCurrentService(serviceComponent);
        } else {
            navigate(-1);
        }
    }, [navigate, title]);

    const servicePrices = currentService?.prices?.map((cardData, index) => {
        return (
            <PriceCard key={index} {...cardData} services={currentService.services} type={title} />
        );
    });

    const productLogoDisplay = currentService?.productLogos?.map((l) => {
        return <img key={l} src={require(`../../../../Assets/Images/Logos/Products/${l}`)} alt={l} />;
    });

    return (
        <>
            <Helmet>
                <title>{`King Auto Booth - ${currentService.serviceName}`}</title>
                <meta
                    name='description'
                    content={currentService.description && currentService.description.slice(0, 100)}
                />
                <link rel='canonical' href={'/' + title} />
            </Helmet>

            <HeaderComponent />
            <div className={styles.container}>
                <h1>{`${currentService.serviceName}`}</h1>
                <p>{currentService.description}</p>
                <br />
                {descriptionCorrector(currentService.points)}

                {currentService.productLogos ? 
                    <>
                        <h2>Products we use</h2>
                        <div className={styles.productLogoContainer}>
                            {productLogoDisplay}
                        </div>
                    </>
                    
                : null}

            </div>

            <div className={styles.priceContainer}>{servicePrices}</div>
            {filteredData.length > 0 ? <Stories data={filteredData} /> : null}

            <FooterComponent />
        </>
    );
};

export default Services;

export const descriptionCorrector = (text) => {
    const formattedText = text?.split('\n');

    let result = '';
    if (text) {
        result = (
            <>
                {formattedText.map((text, index) => (
                    <React.Fragment key={index}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: text.includes('<b>') ? `<b>${text}</b>` : text,
                            }}
                        ></p>
                        <br />
                    </React.Fragment>
                ))}
            </>
        );
    }

    return result;
};
