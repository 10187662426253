import styles from './header.module.scss';
import King from '../../Assets/Images/Logos/Our Logos/King.png';

const Header = () => {
    return (
        <>
            <div className={styles.container}>
                <a href='/'>
                    <img src={King} alt='King Auto Booth' />
                </a>
            </div>
        </>
    );
};

export default Header;
