import { useNavigate } from 'react-router-dom';
import styles from './serviceCard.module.scss';

import Checkmark from '../../Assets/Images/Icons/Other/blue-check.png';

const ServiceCard = (props) => {
    const navigate = useNavigate();

    return (
        <div
            className={styles.container}
            onClick={() => navigate(`/services/${props.serviceName}`)}
        >
            {props.mobileAvailable ? (
                <div className={styles.quoteCheck}>
                    <p>Mobile available</p>
                    <img src={Checkmark} alt='Checkmark' />
                </div>
            ) : (
                <p></p>
            )}
            <img
                src={require(`../../Assets/Images/Icons/Service/${props.icon}`)}
                alt={props.serviceName}
            />
            <p>{props.serviceName}</p>
        </div>
    );
};

export default ServiceCard;
