import React, { useEffect, useState } from 'react';
import { getDashboard, updateDashboardData } from '../../../Services/api.service';
import Loader from '../../../Components/Loader/Loader';
import { Chart } from 'react-google-charts';
import styles from './dashboard.module.scss';
import { Helmet } from 'react-helmet-async';
import Button from '../../../Components/Button/Button';

const Dashboard = () => {
    const role = localStorage.getItem('role');
    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState([]);

    useEffect(() => {
        (async () => {
            setDashboardData(await getDashboard());
            setLoading(false);
        })();
    }, []);

    const chartHeightCalculator = (data) => {
        const length = data.length * 40;
        return `${length}px`;
    };

    let invoicesPieChart,
        invoicesMonthly,
        overallCharts,
        atmEtransferYearly,
        atmEtransferCharts,
        eTransferCharts,
        expensesChart,
        eTransferLastThreeMonths,
        expenseYearly;

    if (dashboardData['result']) {
        const data = dashboardData['result'];

        invoicesPieChart = (
            <div key='invoicesPieChart'>
                <Chart
                    className={styles.chartWrapper}
                    chartType='PieChart'
                    data={data['invoicesPieChart']['companyPerformance']}
                    options={{
                        legend: 'left',
                        is3D: true,
                        sliceVisibilityThreshold: 0.017, // 20%
                    }}
                />
            </div>
        );

        invoicesMonthly = (
            <div className={styles.chartContainer} key='invoicesMonthly'>
                <Chart
                    className={styles.chartWrapper}
                    chartType='Bar'
                    width='100%'
                    height='400px'
                    data={data['invoicesMonthly']['companyPerformance']}
                    options={{
                        legend: { position: 'left' },
                        chart: {
                            subtitle: 'Monthly Invoices',
                        },
                    }}
                />
            </div>
        );

        overallCharts = (
            <div className={styles.chartContainer} key='overallCharts'>
                <Chart
                    className={styles.chartWrapper}
                    chartType='Bar'
                    width='100%'
                    height='400px'
                    data={data['overallCharts']['companyPerformance']}
                    options={{
                        legend: { position: 'left' },
                        chart: {
                            subtitle: 'Monthly Debit vs Expense',
                        },
                    }}
                />
            </div>
        );

        atmEtransferYearly = (
            <div key='expenseYearly'>
                <Chart
                    className={styles.chartWrapper}
                    chartType='PieChart'
                    data={data['expenseYearly']['companyPerformance']}
                    options={{
                        legend: 'left',
                        is3D: true,
                        sliceVisibilityThreshold: 0.016, // 20%
                    }}
                />
            </div>
        );

        atmEtransferCharts = (
            <div className={styles.chartContainer} key='atmEtransferCharts'>
                <Chart
                    className={styles.chartWrapper}
                    chartType='Bar'
                    width='100%'
                    height='400px'
                    data={data['atmEtransferCharts']['companyPerformance']}
                    options={{
                        legend: { position: 'left' },
                        chart: {
                            subtitle: 'Monthly Atm and E-Transfer',
                        },
                    }}
                />
            </div>
        );

        eTransferCharts = (
            <div key='eTransferCharts'>
                <Chart
                    className={styles.chartWrapper}
                    chartType='PieChart'
                    data={data['eTransferCharts']['companyPerformance']}
                    options={{
                        legend: 'left',
                        is3D: true,
                        sliceVisibilityThreshold: 0.017, // 20%
                    }}
                />
            </div>
        );

        expensesChart = (
            <div className={styles.chartContainer} key='expensesChart'>
                <Chart
                    className={styles.chartWrapper}
                    chartType='Bar'
                    width='100%'
                    height='500px'
                    data={data['expensesChart']['companyPerformance']}
                    options={{
                        legend: { position: 'left' },
                        chart: {
                            subtitle: 'Expenses for the past 3 months',
                        },
                    }}
                />
            </div>
        );

        eTransferLastThreeMonths = (
            <div className={styles.chartContainer} key='eTransferLastThreeMonths'>
                <Chart
                    className={styles.chartWrapper}
                    chartType='Bar'
                    width='100%'
                    height={chartHeightCalculator(data)}
                    data={data['eTransferLastThreeMonths']['companyPerformance']}
                    options={{
                        legend: { position: 'left' },
                        chart: {
                            subtitle: 'E-Transfers for the past 3 months',
                        },
                    }}
                />
            </div>
        );

        expenseYearly = (
            <div key='expenseYearly'>
                <Chart
                    className={styles.chartWrapper}
                    chartType='PieChart'
                    data={data['expenseYearly']['companyPerformance']}
                    options={{
                        legend: 'left',
                        is3D: true,
                        sliceVisibilityThreshold: 0.016, // 20%
                    }}
                />
            </div>
        );
    }

    function syncDashboardData() {
        setLoading(true);
        updateDashboardData().then(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>King Auto Booth - Admin</title>
                <meta name='robots' content='noindex' />
            </Helmet>
            <div className={styles.placeholder} />
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.dashboardWrapper}>
                        <p>{dashboardData['message'] ?? ''}</p>
                        {overallCharts ? (
                            <>
                                <h2>Bank</h2>
                                {overallCharts}
                            </>
                        ) : null}
                        {atmEtransferYearly ? (
                            <>
                                <h2>Atm & E-Transfer</h2>
                                <div className={styles.pieChartWrapper}>{atmEtransferYearly}</div>
                                {atmEtransferCharts}
                            </>
                        ) : null}
                        {eTransferCharts ? (
                            <>
                                <h2>E-Transfer</h2>
                                <div className={styles.pieChartWrapper}>{eTransferCharts}</div>
                                {eTransferLastThreeMonths}
                            </>
                        ) : null}
                        {expensesChart ? (
                            <>
                                <h2>Expenses</h2>
                                <div className={styles.pieChartWrapper}>{expenseYearly}</div>
                                {expensesChart}
                            </>
                        ) : null}
                        {invoicesMonthly ? (
                            <>
                                <h2>Invoices</h2>
                                <div className={styles.pieChartWrapper}>{invoicesPieChart}</div>
                                {invoicesMonthly}
                            </>
                        ) : null}
                        {role === 'Admin' ? (
                            <div className={styles.buttonWrapper}>
                                <Button
                                    className={styles.button}
                                    loading={loading}
                                    buttonText='Reload Data'
                                    onClick={syncDashboardData}
                                />
                            </div>
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
};

export default Dashboard;
