import { atom } from 'recoil';

export const loadingState = atom({
    key: 'loadingState',
    default: false,
});

export const itemCountState = atom({
    key: 'itemCountState',
    default: 0,
});

export const screenWidthState = atom({
    key: 'screenWidthState',
    default: window.innerWidth,
});