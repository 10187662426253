import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './navigation.module.scss';
import { itemCountState } from '../../Recoil/Atoms';
import Menu from '../../Assets/Images/Icons/Sidebar/menu.png';
import King from '../../Assets/Images/Logos/Our Logos/King.png';
import Phone from '../../Assets/Images/Icons/Social/phone.png';
import Email from '../../Assets/Images/Icons/Social/email.png';
import ShoppingBag from '../../Assets/Images/Icons/Sidebar/shopping-bag.png';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../Recoil/Atoms';
import { syncDb } from '../../Services/api.service';
import Cart from '../ShoppingCart/ShoppingCart';
import { getItem } from '../../Services/storage.handler';

const Navigation = (props) => {
    const navigate = useNavigate();
    const [hideNavBar, setHideNavBar] = useState(true);
    const [itemCount, setItemCount] = useRecoilState(itemCountState);
    const [displayCart, setDisplayCart] = useState(false);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [displaySideBar, setDisplaySideBar] = useState(false);
    const [, setLoading] = useRecoilState(loadingState);
    const currentUrl = window.location.pathname;
    const isAdmin = currentUrl.includes('admin');

    useEffect(() => {
        if (!isAdmin) {
            const handleScroll = () => {
                if (window.scrollY > 80) {
                    setHideNavBar(false);
                } else {
                    setHideNavBar(true);
                }
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
        setHideNavBar(false);
    }, [isAdmin]);

    useEffect(() => {
        setItemCount(getItem('cart')?.length ?? 0);
    }, [setItemCount]);

    const handleBackdrop = () => {
        setDisplayBackdrop(!displayBackdrop);
        const body = document.body;
        if (displayBackdrop) {
            body.style.overflow = 'auto';
        } else {
            body.style.overflow = 'hidden';
        }
    };

    let menuRender = props.menuItems.map((m) => {
        return (
            <h4
                key={m.id}
                onClick={() => {
                    handleBackdrop();
                    setDisplaySideBar(false);
                    navigate(`${m.url}`);
                }}
            >
                {m.name}
            </h4>
        );
    });

    async function syncBtnHandler() {
        setLoading(true);
        await syncDb(true);
        setLoading(false);
        handleBackdrop();
    }

    return (
        <>
            {displayBackdrop ? (
                <div
                    className={styles.backDrop}
                    onClick={() => {
                        handleBackdrop();
                        setDisplaySideBar(false);
                        setDisplayCart(false);
                    }}
                />
            ) : null}

            {displaySideBar ? (
                <div className={styles.sideBar}>
                    <div
                        onClick={() => {
                            navigate('/');
                        }}
                        className={styles.sideBarTop}
                    >
                        <img src={King} alt='King Auto Booth' />
                    </div>
                    <div className={styles.sideBarMiddle}>
                        {menuRender}
                        {isAdmin ? <h4 onClick={syncBtnHandler}>Sync</h4> : null}
                    </div>
                    {isAdmin ? null : (
                        <div className={styles.sideBarFooter}>
                            <div className={styles.sideBarFooterItem}>
                                <a href='tel:+1 226-753-2070'>
                                    <img src={Phone} alt='Phone' />
                                    <h4>+1 226-753-2070</h4>
                                </a>
                            </div>
                            <div className={styles.sideBarFooterItem}>
                                <a href='mailto: contact@kingautobooth.com'>
                                    <img src={Email} alt='Email' />
                                    <h4>contact@kingautobooth.com</h4>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            ) : null}

            {displayCart ? <Cart handleCloseButton={() => {
                handleBackdrop();
                setDisplayCart(false);
            }} /> : null}

            <div className={styles.navigationBar}>
                {hideNavBar ? (
                    <div />
                ) : (
                    <>
                        <a className={styles.navigationLogo} href='/'>
                            <img src={King} alt='King Auto Booth' />
                        </a>
                    </>
                )}
                {isAdmin ? <h5>{new Date().toDateString()}</h5> : null}
                <div className={styles.bagMenuContainer}>
                    <div className={styles.shoppingBagCount}>
                        <p>{itemCount}</p>
                    </div>
                    <img
                        src={ShoppingBag}
                        alt='Bag'
                        onClick={() => {
                            handleBackdrop();
                            setDisplayCart(true);
                        }}
                    />
                    <img
                        src={Menu}
                        alt='Menu'
                        onClick={() => {
                            handleBackdrop();
                            setDisplaySideBar(true);
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default Navigation;
