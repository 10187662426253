import React, { useEffect, useRef, useState } from 'react';
import styles from './gallery.module.scss';
import King from '../../../Assets/Images/Logos/Our Logos/King.png';
import Close from '../../../Assets/Images/Icons/Other/cross.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StoryData from '../../../Data/Gallery.json';
import { Helmet } from 'react-helmet-async';

let highlights;

const Gallery = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    if (location.state) {
        highlights = location.state;
    } else {
        highlights = StoryData.filter((s) => s.highlight === true);
    }

    const [id] = useState(params.id);
    const [videos] = useState(highlights);
    const [currentVideo, setCurrentVideo] = useState(0);
    const [currentVideoLength, setCurrentVideoLength] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoEl = useRef(null);

    useEffect(() => {
        if (id) {
            setCurrentVideo(id - 1);
        }
    }, [id]);

    useEffect(() => {
        const progressContainer = document.getElementById('progress-container');
        const width = 100 / videos.length;

        for (let i = 0; i < videos.length; i++) {
            const progressBar = document.createElement('div');
            progressBar.classList.add(styles.progressBar);
            progressBar.style.width = width + '%';
            progressContainer.appendChild(progressBar);

            const innerFill = document.createElement('div');
            const uniqueId = `progress-bar-fill-${i}`; // Generate a unique id
            innerFill.id = uniqueId;
            innerFill.classList.add(styles.progressBarFill);
            progressBar.appendChild(innerFill); // Add innerFill as a child of progressBar
        }
        // eslint-disable-next-line
    }, [videos]);

    useEffect(() => {
        if (!isLoading || !isPlaying) {
            const innerFill = document.getElementById(`progress-bar-fill-${currentVideo}`);
            const increment = 100 / currentVideoLength;
            const intervalDuration = 1000;
            let width = 0;

            const interval = setInterval(() => {
                if (isLoading || !isPlaying) {
                    // Pause the interval if isLoading is true
                    return;
                }

                if (width >= 100) {
                    handleClick(true);
                } else {
                    width += increment;
                    innerFill.style.width = width + '%';
                }
            }, intervalDuration);

            return () => {
                clearInterval(interval); // Clear the interval when the component unmounts or when currentVideoLength changes.
            };
        }
        // eslint-disable-next-line
    }, [currentVideoLength, currentVideo, isLoading, isPlaying]);

    const handleClick = (direction) => {
        let nextVideo;

        if (direction) {
            const innerFill = document.getElementById(`progress-bar-fill-${currentVideo}`);
            innerFill.style.width = '100%';

            if (currentVideo < videos.length - 1) {
                nextVideo = currentVideo + 1;
            }

            if (currentVideo === videos.length - 1) {
                nextVideo = 0;
            }
        }

        if (!direction) {
            const innerFill = document.getElementById(`progress-bar-fill-${currentVideo}`);
            innerFill.style.width = '0%';

            if (currentVideo > 0) {
                nextVideo = currentVideo - 1;
            }

            if (currentVideo === 0) {
                nextVideo = videos.length - 1;
            }

            const innerFillNext = document.getElementById(`progress-bar-fill-${nextVideo}`);
            innerFillNext.style.width = '0%';
        }
        setCurrentVideo(nextVideo);
    };

    useEffect(() => {
        videoEl.current?.load();
    }, [currentVideo]);

    const getVideoLength = () => {
        const video = videoEl.current;
        if (!video) return;

        setCurrentVideoLength(video.duration);
    };

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 50;
        const isRightSwipe = distance < -50;
        if (isLeftSwipe) {
            handleClick(true);
        }

        if (isRightSwipe) {
            handleClick(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>King Auto Booth - Our Stories</title>
                <meta name='description' content='Witness as we create remarkable achievements.' />
                <link rel='canonical' href='/gallery' />
            </Helmet>
            <div className={styles.container}>
                <div
                    className={styles.overlayBox}
                    style={{ left: 0 }}
                    onClick={() => handleClick(false)}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                />

                <div className={styles.stories}>
                    <video
                        loading='lazy'
                        id='current-video'
                        ref={videoEl}
                        onWaiting={() => setIsLoading(true)}
                        onPlaying={() => setIsPlaying(true)}
                        onLoadedMetadata={getVideoLength}
                        key={videos[currentVideo]['id']}
                        autoPlay
                        muted
                        playsInline
                    >
                        <source
                            src={require(`../../../Assets/Videos/${videos[currentVideo]['path']}`)}
                            type='video/mp4'
                        />
                    </video>
                </div>

                <div
                    className={styles.overlayBox}
                    style={{ right: 0 }}
                    onClick={() => handleClick(true)}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                />

                <div id='progress-container' className={styles.progressBarContainer} />

                <div className={styles.profileBar}>
                    <img
                        src={King}
                        alt='King Auto Booth'
                        onClick={() => {
                            navigate(`/`);
                        }}
                    />
                    <img
                        src={Close}
                        style={{ width: 'min(10vw, 60px)' }}
                        alt='Close'
                        onClick={() => {
                            navigate(`/`);
                        }}
                    />
                </div>

                <div className={styles.postDetails}>
                    <p>{videos[currentVideo].location}</p>
                    <p>{videos[currentVideo].date}</p>
                </div>
            </div>
        </>
    );
};

export default Gallery;
