import styles from './inputField.module.scss';

const InputField = (props) => {
    return (
        <div className={styles.inputFieldContainer}>
            <p>
                <b>{props.label}</b>
            </p>
            <div className={styles.inputField}>
                {props.path ? (
                    <img
                        src={require(`../../Assets/Images/Icons/Other/${props.path}`)}
                        alt={props.label}
                    />
                ) : null}
                <input
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    type={props.type}
                    value={props.value}
                    maxLength={props.maxLength}
                    pattern={props.pattern}
                />
            </div>
        </div>
    );
};

export default InputField;
