import { useNavigate, useParams } from 'react-router-dom';
import Pages from '../../../Data/Editor.json';
import { useEffect, useState } from 'react';
import {
    deleteClient,
    getClient,
    getEmployee,
    getInvoice,
    getQuote,
    getService,
    updateClient,
    updateEmployee,
    deleteEmployee,
    receivePayment,
    downloadInvoiceFile,
    deleteInvoice,
    createQuoteService,
    duplicateService,
    updateService,
    deleteService,
    createClient,
    createEmployee,
    createService,
} from '../../../Services/api.service';
import InputField from '../../../Components/InputField/InputField';
import Checkbox from '../../../Components/Checkbox/Checkbox';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import TextArea from '../../../Components/TextArea/TextArea';
import Button from '../../../Components/Button/Button';
import Loader from '../../../Components/Loader/Loader';
import styles from './editor.module.scss';
import DatePicker, { dateToUnixTimeStamp } from '../../../Components/DatePicker/DatePicker';
import { Helmet } from 'react-helmet-async';

const Editor = () => {
    const navigate = useNavigate();
    const { title, id } = useParams();
    const [page, setPage] = useState({});
    const [currentItem, setCurrentItem] = useState({});
    const [loading, setLoading] = useState(true);

    const functionMap = {
        getEmployee: { func: getEmployee, params: [id] },
        getClient: { func: getClient, params: [id] },
        getInvoice: { func: getInvoice, params: [id] },
        getQuote: { func: getQuote, params: [id] },
        getService: { func: getService, params: [id] },
        updateClient: { func: updateClient, params: [id, currentItem] },
        deleteClient: { func: deleteClient, params: [id] },
        updateEmployee: { func: updateEmployee, params: [id, currentItem] },
        deleteEmployee: { func: deleteEmployee, params: [id] },
        receivePayment: { func: receivePayment, params: [id] },
        downloadInvoiceFile: { func: downloadInvoiceFile, params: [currentItem.invoiceNumber] },
        deleteInvoice: { func: deleteInvoice, params: [id] },
        createQuoteService: { func: createQuoteService, params: [id] },
        duplicateService: { func: duplicateService, params: [id] },
        updateService: { func: updateService, params: [id, currentItem] },
        deleteService: { func: deleteService, params: [id] },
        createClient: { func: createClient, params: [{ ...currentItem }] },
        createEmployee: { func: createEmployee, params: [{ ...currentItem }] },
        createService: { func: createService, params: [currentItem] },
    };

    useEffect(() => {
        (async () => {
            const foundPage = Pages.find((p) => p.title === title);
            setPage(foundPage || {});
            if (id !== 'new') {
                const { func, params } = functionMap[foundPage.getCall] || {};

                if (func) {
                    setCurrentItem(await func(...params));
                } else {
                    console.error(`Function not found for action: ${foundPage.getCall}`);
                }
            } else {
                const { sinceTs, ...restInputFields } = foundPage['inputFields'];
                setCurrentItem(
                    Object.fromEntries(Object.keys(restInputFields).map((key) => [key, ''])),
                );
            }
            setLoading(false);
        })();
        // eslint-disable-next-line
    }, [title, id]);

    const getCustomerInfo = async (id) => {
        const client = await getClient(id);
        return { address: client.address, city: client.city, postalCode: client.postalCode };
    };

    const inputRenderHelper = (key, value) => {
        if (page['inputFields'][key]) {
            const inputObj = page['inputFields'][key];
            const label = inputObj['label'];
            const type = inputObj['type'];
            const disabled = inputObj['disabled'];
            const dropdownCall = inputObj['dropdownCall'];
            const displayKey = inputObj['displayKey'];

            switch (type) {
                case 'input':
                    return (
                        <InputField
                            key={key}
                            label={label}
                            disabled={disabled}
                            value={value ? value : ''}
                            onChange={(e) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: e.target.value,
                                }));
                            }}
                        />
                    );
                case 'dropdown':
                    return (
                        <Dropdown
                            key={key}
                            label={label}
                            value={value ? value : -1}
                            options={dropdownCall}
                            displayKey={displayKey}
                            onChange={async (e) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: e.target.value,
                                }));

                                if (label === 'Client') {
                                    const clientInfo = await getCustomerInfo(e.target.value);
                                    setCurrentItem((prev) => ({
                                        ...prev,
                                        address: clientInfo.address,
                                        city: clientInfo.city,
                                        postalCode: clientInfo.postalCode,
                                    }));
                                }
                            }}
                        />
                    );
                case 'textarea':
                    return (
                        <TextArea
                            key={key}
                            label={label}
                            disabled={disabled}
                            value={value ? value : ''}
                            onChange={(e) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: e.target.value,
                                }));
                            }}
                        />
                    );
                case 'checkbox':
                    if (value === undefined || value === '') {
                        setCurrentItem((prev) => ({
                            ...prev,
                            [key]: false,
                        }));
                    }
                    return (
                        <Checkbox
                            key={key}
                            label={label}
                            checked={value ? value : false}
                            onChange={(e) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: e.target.checked,
                                }));
                            }}
                        />
                    );
                case 'date':
                    return (
                        <DatePicker
                            key={key}
                            initialTimestamp={value ? value : dateToUnixTimeStamp(new Date())}
                            disabled={disabled}
                            label={label}
                            onChange={(unixTimeStamp) => {
                                setCurrentItem((prev) => ({
                                    ...prev,
                                    [key]: unixTimeStamp,
                                }));
                            }}
                        />
                    );
                default:
                    return <h1>{type} needs a new component</h1>;
            }
        }
    };

    const renderInputFields = () => {
        if (page['ignoreKeys']) {
            return (
                <>
                    {Object.entries(currentItem)
                        .filter(([key]) => !page['ignoreKeys'].includes(key))
                        .map(([key, value]) => inputRenderHelper(key, value))}
                </>
            );
        }
    };

    const createFunction = async (action) => {
        setLoading(true);
        if (functionMap[action]) {
            const { func, params } = functionMap[action];
            try {
                await func(...params);
                navigate(-1);
            } catch (error) {
                console.error(`Error calling function ${action}: ${error.message}`);
            }
        } else {
            console.error(`Function not found for action: ${action}`);
        }
        setLoading(false);
    };

    const renderButtons = () => {
        if (page['buttons']) {
            return (
                <>
                    {page['buttons'].map((button, index) => (
                        <Button
                            key={index}
                            onClick={() => createFunction([Object.values(button)[0]])}
                            buttonText={Object.keys(button)[0]}
                        />
                    ))}
                </>
            );
        }
    };

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            <div className={styles.placeholder} />
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <h1>Edit {page.name}</h1>
                    <div className={styles.inputContainer}>{renderInputFields()}</div>
                    <div className={styles.buttonContainer}>
                        {id === 'new' ? (
                            <Button
                                buttonText='Create'
                                onClick={() => createFunction(page['createCall'])}
                            />
                        ) : (
                            renderButtons()
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Editor;
