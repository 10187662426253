import styles from './button.module.scss';

const Button = (props) => {
    return (
        <button className={styles.button} disabled={props.loading} onClick={props.onClick}>
            {props.submitted ? (
                props.loading ? (
                    <div className={styles.buttonLoader}>

                    </div>
                ) : (
                    <div className={styles.responseMessage}>
                        {props.responseMessage}
                    </div>
                )
            ) : (
                props.buttonText
            )}
        </button>
    );
};

export default Button;
