import React, { useEffect, useState } from 'react';
import styles from './datepicker.module.scss';

const DatePicker = (props) => {
    const [formattedDate, setFormattedDate] = useState('');

    const handleDateChange = (event) => {
        const unixTimeStamp = dateToUnixTimeStamp(event.target.value);
        setTimestamp(unixTimeStamp);
        props.onChange(unixTimeStamp);
    };

    const setTimestamp = (unixTimestamp) => {
        const formattedDateTime = unixTimestampToDateTime(unixTimestamp);
        setFormattedDate(formattedDateTime);
    };

    useEffect(() => {
        setTimestamp(props.initialTimestamp);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.inputFieldContainer}>
            <p>
                <b>{props.label}</b>
            </p>
            <div className={`${styles.inputField} ${props.className}`}>
                <input
                    type='datetime-local'
                    value={formattedDate}
                    onChange={handleDateChange}
                    disabled={props.disabled}
                />
            </div>
        </div>
    );
};

export default DatePicker;

export const dateToUnixTimeStamp = (dateTimeValue) => {
    return Math.floor(new Date(dateTimeValue).getTime() / 1000);
};

export const unixTimestampToDateTime = (unixTimestamp) => {
    const currentDate = new Date(unixTimestamp * 1000);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDateTime;
};
