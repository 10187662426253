import React, { useState } from 'react';
import Stories from '../../../Components/Stories/Stories';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import ServiceCard from '../../../Components/ServiceCard/ServiceCard';
import Contact from '../../../Components/Contact/Contact';
import styles from './home.module.scss';

import Federated from '../../../Assets/Images/Icons/Insurance/insurance.png';
import Wsib from '../../../Assets/Images/Icons/Insurance/wsib.png';

import serviceList from '../../../Data/Services.json';
import StoryData from '../../../Data/Gallery.json';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    const [cleaningServices] = useState(serviceList);

    let ourServices = cleaningServices.map((service) => {
        return (
            <ServiceCard
                key={service.id}
                id={service.id}
                serviceName={service.serviceName}
                icon={service.icon}
                type={service.type}
                mobileAvailable={service.mobileAvailable}
            />
        );
    });

    return (
        <>
            <Helmet>
                <title>King Auto Booth & Spa</title>
                <meta
                    name='description'
                    content='King Auto Booth is committed to providing premier car detailing, wrapping, and
                    ceramic coating services at competitive rates since March 2024.'
                />
                <link rel='canonical' href='/' />
            </Helmet>
            <Header />
            <Stories data={StoryData.filter((s) => s.highlight === true)} />

            <div id='About' className={styles.sectionContainer}>
                <h1>About Us</h1>
                <p>
                    King Auto Booth is committed to providing premier car detailing, wrapping, and
                    ceramic coating services at competitive rates. Our mission is to exceed your
                    expectations by delivering exceptional services that elevate the appearance and
                    protection of your vehicle. We take great pride in our craftsmanship and are
                    dedicated to ensuring your satisfaction throughout every step of the process.
                    Our team values customer experience, and we make it our priority to follow up
                    after each service to guarantee your utmost contentment.
                    <br />
                    <br />
                    At King Auto Booth, reliability is at the core of our ethos. We consistently
                    fulfill our promises and strive to build trust with our clients. With a team of
                    seasoned professionals, each with extensive expertise in their respective
                    fields, we have been serving our customers since March 2024. Our skilled
                    workforce is exclusively comprised of individuals who bring their professional
                    knowledge to every project, ensuring the highest quality results.
                    <br />
                    <br />
                    King Auto Booth caters to a diverse clientele, offering top-notch services that
                    enhance the aesthetics and protection of vehicles. Discover the unparalleled
                    difference of our services as we bring over five years of collective industry
                    experience to every detailing, wrapping, and ceramic coating project.
                </p>
                <div className={styles.aboutImages}>
                    <img src={Federated} alt='Federated' />
                    We provide up to $5,000,000 in Federated insurance coverage to protect your
                    vehicle against any potential damages that may occur during our services.
                    <br />
                    <br />
                    All team members undergo a screening process that includes a check of their
                    criminal records and references.
                    <img src={Wsib} alt='Wsib' />
                </div>
            </div>

            <div id='Services' className={styles.serviceWrapper}>
                <h1>Our Services</h1>
                <div className={styles.serviceContainer}>{ourServices}</div>
            </div>

            <Contact />

            <Footer />
        </>
    );
};

export default Home;
